import React from "react"
import styled from "styled-components"

import TradeList from "./contractor/tradeList"
import CompanyInfo, { AccountType } from "./signupInfo"
import { useQuery } from "@apollo/react-hooks"
import { useArray } from "react-hanger"
import { GET_TRADES } from "../../apollo/queries"
import { Trade } from "../../apollo/types"
import Loading from "../ui/Loading";

interface GetTrades {
  trades: Trade[]
}

interface Props {
  skipEmail?: boolean
  onSignup?: () => void
  embedded?: boolean
}

const ContractorFullSignup = (props: Props) => {
  const selectedTrades = useArray([])
  const { data, loading } = useQuery<GetTrades>(GET_TRADES, {
    fetchPolicy: "network-only",
  })

  if (loading) return <Loading />

  const trades: Trade[] = data.trades;

  return (
    <Container embedded={props.embedded}>
      <FormContainer>
        <TradeList selectedTrades={selectedTrades.value} trades={trades} toggleTrade={(trade, checked) => checked
          ? selectedTrades.add(trade)
          : selectedTrades.removeById(trade.id)}
        />
        <CompanyInfo
          {...props}
          hasTrades={trades && trades.length > 0}
          accountType={AccountType.CONTRACTOR}
          selectedTrades={selectedTrades}
        />
      </FormContainer>
    </Container>
  )
}

const Container = styled.div<{embedded?: boolean}>`
  width: 100${({embedded}) => embedded ? '%' : 'vw'};
  display: flex;
  flex: 1;
  flex-direction: row;
  height: auto;
  align-items: center;
`

const FormContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-evenly;
  align-items: stretch;
  flex-wrap: wrap;
`

export default ContractorFullSignup

import React from 'react';
import styled from 'styled-components';

import ApiCaller from '../../utils/api'; // This should be something else, I don't know
import BasicInfo, { AccountType } from './signupInfo';

const emailRegex = /^.+@.+\..+$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/;

class HomeownerFullSignup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      submitting: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(payload) {
    const errors = {};

    if (!payload.state) {
      errors.state = 'Select a state';
    }
    if (!emailRegex.test(payload.email)) {
      errors.email = 'Must provide a valid email';
    }
    if (!passwordRegex.test(payload.password)) {
      errors.password = 'Password must be 6 or more characters and contain uppercase, lowercase, and numbers';
    }
    if (payload.password !== payload.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    this.setState({ errors: {}, submitting: true });
    let badMsg;

    try {
      // Do data mapping here
      const { data, error, message } = await new Promise(
        done => ApiCaller(
          'signup',
          {...payload, source: 'web'}, 
          done
        )
      );

      const { result } = data || {};
      if (error) {
        badMsg = message || 'Could not sign up, please try again later';
      }
      if (result.error) {
        badMsg = result.message || 'Could not sign up, please try again later';
      }
    } catch (e) {
      console.warn('Api error: ', e);
      badMsg = e.message || e.msg || 'Could not sign up, please try again later';
    }

    if (badMsg) {
      this.setState({
        submitting: false,
        errors: { message: badMsg },
      });
      return;
    }

    this.setState({ submitting: false });

    // Do your navigation here! I would recommend changing the API
    // to return a valid token with singup and just use that
  }

  render() {
    const { submitting, errors } = this.state;

    return (
      <Container embedded={this.props.embedded}>
        <FormContainer>
          <BasicInfo {...this.props} submitting={submitting} accountType={AccountType.HOME_OWNER} hasTrades errors={errors} />
        </FormContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  width: 100${({embedded}) => embedded ? '%' : 'vw'};
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const FormContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-evenly;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
`;

export default HomeownerFullSignup;

import React from 'react'
import DisastrousErrorHandler from '../../components/ui/DisastrousErrorHandler'
import AuthGuard from '../../components/ui/AuthGuard'
import AdminSidebar from '../../components/adminSidebar'
import AdminCreateAccount from '../../components/admin/CreateAccount'

const AdminCreateAccountScreen = () => (
  <DisastrousErrorHandler>
    <AuthGuard allowed="ADMIN">
	<AdminSidebar>
		<AdminCreateAccount />
	</AdminSidebar>

		</AuthGuard>
	</DisastrousErrorHandler>
);

export default AdminCreateAccountScreen;

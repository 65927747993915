import * as React from 'react'
import styled from 'styled-components'
import { Tabs, Tab, Typography } from '@material-ui/core'
import HomeownerFullSignup from '../signup/homeownerFullSignup'
import ContractorFullSignup from '../signup/contractorFullSignup'
import Swal from 'sweetalert2'

const JobsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 12px 40px 40px 40px;
	width: auto;
	height: auto;
	min-height: 100%;

	@media (max-width: 850px) {
		padding: 16px;
		padding-top: 16px;
		padding-bottom: 66px;
	}
`

const AdminJobs = () => {
	const [tab, setTab] = React.useState(0)
	const handleTabChange = (e: React.ChangeEvent<{}>, newVal: number) =>
		setTab(newVal)

	const onSignup = async () => {
		await Swal.fire('User Created')
		window.location.reload()
	}

	return (
		<JobsContainer>
			<Tabs
				style={{ marginBottom: 24 }}
				value={tab}
				onChange={handleTabChange}
				centered
			>
				<Tab style={{ color: 'white' }} label={`Homeowner`} />
				<Tab style={{ color: 'white' }} label={`Contractor`} />
			</Tabs>
			{tab === 0 ? <HomeownerFullSignup embedded skipEmail onSignup={onSignup} /> : <ContractorFullSignup embedded skipEmail onSignup={onSignup} />}
		</JobsContainer>
	)
}

export default AdminJobs

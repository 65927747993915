import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  withStyles,
  Card,
} from '@material-ui/core';
import styled from 'styled-components';

import { formatTradeGroup } from '../../../utils/helpers'
import { Trade } from '../../../apollo/types'

const styles = {
  card: {
    width: 542,
    marginTop: 12,
    marginBottom: 12,
    maxWidth: '95vw',
    flex: 1,
    padding: 22,
  },
  checkbox: {
    paddingTop: 6,
    paddingBottom: 6,
  },
};

interface Props {
  trades: Trade[]
  selectedTrades: Trade[]
  classes: any
  toggleTrade: (trade: Trade, checked: boolean) => void
  hideCost?: boolean
  noContainer?: boolean
}

const ContractorSignupTrades = ({ trades, selectedTrades, classes, toggleTrade, hideCost, noContainer }: Props) => {
  const internal = (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel component="legend">Trades We Perform</FormLabel>
      <FormHelperText style={{ marginBottom: 8 }}>Choose at least one</FormHelperText>
      <FormGroup>
        {trades && trades.map((trade, i) => (
          <FormControlLabel
            key={trade.id}
            control={(
              <Checkbox
                className={classes.checkbox}
                checked={selectedTrades.map(st => st.id).includes(trade.id)}
                onChange={
                  ({ target: { checked } }) => toggleTrade(trade, checked)
                }
                value={trade.id}
              />
            )}
            label={formatTradeGroup(trade.group)}
          />
        ))}
      </FormGroup>
    </FormControl>
  )

  if (noContainer) {
    return internal
  }

  return (
    <Container>
      <Card className={classes.card}>
        {internal}
      </Card>
    </Container>
  )
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 95vw;
`;

ContractorSignupTrades.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(ContractorSignupTrades);
